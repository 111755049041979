import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class MenuItem extends Component {
    render() {
        return (
            <div className="d-none d-md-flex flex-md-row align-items-center ml-auto">
                <div className="mr-4">
                    <Link to="/creator" className={this.props.creator}>Creators & Publishers</Link>
                </div>
                <div className="mr-4">
                    <Link to="/grocers" className={this.props.grocers}>Grocers</Link>
                </div>
                <a target="_blank" rel="noopener noreferrer" href={this.props.href} >
                    <button className="text-uppercase btn-orange-download-menud text-white-download-menud mr-1 mr-lg-5 ">
                        download menud
                     </button>
                </a>
            </div>
        )
    }
}
