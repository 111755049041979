import React, { Component } from 'react'
import MainSection from '../sections/grocers/MainSection';
import InformationSection from '../sections/grocers/InformationSection';
import MealDecision from '../sections/grocers/MealDecision';
import EngageShoppers from '../sections/grocers/EngageShoppers';
import ActionableInspiration from '../sections/grocers/ActionableInspiration';
import TurnkeySolution from '../sections/grocers/TurnkeySolution';
import Footer from '../UI/Footer';
export default class Grocers extends Component {
    render() {
        return (
            <div>
                <MainSection/>
                      <InformationSection/>
                      <MealDecision/> 
                      <EngageShoppers/>   
                     <ActionableInspiration/>
                     <TurnkeySolution/>          
                <Footer/>               
            </div>
        )
    }
}
