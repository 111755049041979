import React, { Component } from 'react'
import MainSection from '../sections/creators/MainSection';
import Microsite from '../sections/creators/Microsite';
import CapturingRecipes from '../sections/creators/CapturingRecipes';
import WhiteLabel from '../sections/creators/WhiteLabel';
import  RecipeManagement from '../sections/creators/RecipeManagement';
import TurnkeyInteractive from '../sections/creators/TurnkeyInteractive';
import Footer from '../UI/Footer';
export default class Creators extends Component {
    render() {
        return (
            <div>
                <MainSection/>
                 <Microsite/>
                 <CapturingRecipes/>
                 <WhiteLabel/>
                 <RecipeManagement/>
                  <TurnkeyInteractive/> 
                <Footer/>     
            </div>
        )
    }
}
