import React, { Component } from 'react'
import codified from '../../../assets/icons/codified.svg';
import api from '../../../assets/icons/api.svg';
import simplelist from '../../../assets/icons/simple-lists.svg';
import adminPlateList from '../../../assets/creators/screen/adminPlateList.png'
export default class RecipeManagement extends Component {
    render() {
        return (
            <div className="background-recipe-management">
                <div className="d-flex flex-column flex-xl-row  ml-xl-0">
                    <div className="margin-negative-rectangle-card rectangle-card-menud ml-md-0 order-1 over-flow-menu-d order-xl-0">

                        <img src={adminPlateList} alt="View of recipe content management."/>
                        <div className="margin-xs-left-content">
                            <div className="rectangle-card-menud"></div>
                        </div>
                          
                        
                    </div>

                    <div className="d-flex flex-column order-0 order-xl-1  margin-top-negative">
                        <h5 className="main-title-menud text-uppercase"> Recipe Management</h5>
                        <p className="black-paragraph-menud"> Organize, digitize,<br></br> & optimize content.</p>
                        <p className="text-black-menu-cards mt-4">Recipes can be input into Menūd’s content management system and accessible through Menūd’s API.
                            Recipe creation and organization is efficient and easy. Your front end can leverage Menud’s intelligent backend.
                        </p>
                    </div>
                </div>
                <div className="d-flex justify-content-sm-center mt-5">
                    <h5 className="main-title-menud text-uppercase ml-4 ml-lg-0">Recipes, Meet Technology</h5>
                </div>
                <div className="container">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-center justify-content-sm-around mt-5 ml-2  ml-sm-0">
                        <div className="d-flex flex-sm-column align-items-sm-center">
                            <img src={codified} alt="Codified" className="image-size" />
                            <div className="d-flex flex-column ml-4 ml-sm-0">
                                 <h5 className="title-small-icon text-uppercase mt-3 text-md-center mt-sm-4"> Codified</h5>
                                <div className="d-none d-md-block">
                                    <p className="box-paragraph-menu">
                                        Content is codified, creating<br></br> more value to digital assets.
                                     </p>

                                </div>
                                <div className="d-md-none">
                                     <p className="paragraph-small-icon">
                                        Content is codified, creating more value to digital assets.
                                    </p> 
                                </div> 

                            </div>

                        </div>
                        <div className="d-flex flex-sm-column align-items-sm-center mt-4 mt-sm-0 ml-sm-4 ml-md-0">
                            <img src={simplelist} alt="Simple-list" className="image-size" />
                            <div className="d-flex flex-column ml-4 ml-sm-0">
                                 <h5 className="title-small-icon text-uppercase text-md-center mt-4"> Simple</h5>
                                <div className="d-none d-md-block">
                                    <p className="box-paragraph-menu ">
                                        Building recipes and meal<br></br> plans is as easy as building<br></br> a music playlist.
                                     </p>
                                </div>
                                <div className="d-md-none">
                                     <p className="paragraph-small-icon">
                                        Building recipes and meal plans is as easy as building a music playlist.
                                     </p> 
                                </div> 

                            </div>

                        </div>
                        <div className="d-flex flex-sm-column align-items-sm-center mt-4 mt-sm-0 ml-sm-4 ml-md-0">
                            <img src={api} alt="Simple-list" className="image-size" />
                             <div className="d-flex flex-column ml-4 ml-sm-0">
                                <h5 className=" title-small-icon text-uppercase text-md-center mt-3 mt-sm-4">API-Driven </h5>
                                <div className="d-none d-md-block">
                                    <p className="box-paragraph-menu ">
                                        Content updates are<br></br> immediately reflected on the<br></br> front end product(s).
                                 </p>
                                </div>
                                <div className="d-md-none">
                                     <p className="paragraph-small-icon">
                                    Content updates are immediately reflected on the front end product(s).
                                    </p> 
                                </div>

                            </div> 

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
