import React, { Component } from 'react';
import MainSection from '../sections/brands/MainSection';
import angle from '../../assets/brands/background/web/bg-angle.png';
import anglephone from '../../assets/brands/background/phone/angle-phone.png';
import MealDecision from '../sections/grocers/MealDecision';
import Curation from '../sections/brands/Curation';
import ShopperJourney from '../sections/brands/ShopperJourney';
import Develop from '../sections/brands/Develop';
import Footer from '../UI/Footer';
export default class Brands extends Component {
    render() {
        return (
            <div>
              <MainSection/>
               <div className="d-none d-sm-block">
                  <img src={angle} className="background-angle-yellow" alt="Angle"/>
              </div>
              <div className="d-sm-none">
                  <img src={anglephone} className="background-angle-yellow" alt="Angle"/>
              </div> 
                <MealDecision/> 
               <Curation/>
                <ShopperJourney/> 
                <Develop/>
               <Footer/>   
            </div>
        )
    }
}
