import React, { Component } from 'react'
import Footer from '../UI/Footer';

export default class Tos extends Component {
    render() {
        return (
            <div className="container--tips">
                <div className="container--getting-started">
                    <p class="intro-text">Getting Started</p>
                    <h2>Grabbing a recipe plan and reviewing your grocery list.</h2>
                    <p>We get it. You're tired of asking your partner EVERY night, "What do you want for dinner?!"</p>
                    <p>Fortunately, in just a few taps, we can get you on your way with a recipe plan and complete grocery list.</p>
                    <a href="#table-of-contents" class="btn btn-secondary">
                        TIPS: Table of Contents
                    </a>
                </div>
                <div className="container--table-of-contents" id="table-of-contents">
                    <div className="col">
                        <h3>The Basics</h3>
                        <ul>
                            <li>Grab a recipe plan</li>
                            <li>Customize your plan</li>
                            <li>Find & and more recipes</li>
                            <li>Review grocery list</li>
                            <li>Discover Kitchens</li>
                            <li>Add favorites</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>A Little More. A Little Better.</h3>
                        <ul>
                            <li>Buy groceries</li>
                            <li>Share recipes, meals, and plans</li>
                            <li>Seet or change your portions</li>
                            <li>Add & review snacks and desserts</li>
                            <li>Create and modify your profile</li>
                            <li>Filter & Search to add favorites</li>
                            <li>Change your shop-for days</li>
                            <li>Nutrition information</li>
                        </ul>
                    </div>
                </div>
                <div className="container--basics" id="basics">
                    <h1>The Basics</h1>
                    <div className="container--basic" id="recipe-plan">
                        <h3>The Basics</h3>
                        <h2>Grab a Recipe Plan</h2>
                        <p>Menūd has dozens of meal plans to chose from.  Some are curated by our team. Some are curated by health and food influencers in the Instagram community.  Recipes change every week.</p>
                        <p><strong>To get started:</strong></p>
                        <ol>
                            <li>Tap on a PlateList and review the dishes of the week.</li>
                            <li>Tap "add to plan." Then import your recipe plan to your week.</li>
                        </ol>
                    </div>
                    <div className="container--basic" id="customize-plan">
                        <h3>The Basics</h3>
                        <h2>Customize Plan</h2>
                        <p>Maybe you're schedule is wonky. Or maybe you hate a recipe that's planned to the week. No problem.</p>
                        <p><strong>You can delete, move, and add dishes on your plan. Here's how:</strong></p>
                        <ol>
                            <li>To delete, tap the little arrow with the "x" then confirm.</li>
                            <li>To move, tap and hold a dish then drag it to your preferred day.</li>
                            <li>To add, tap the circle with the "+" sign then select any meal from the recipe catalogue.</li>
                        </ol>
                    </div>
                    <div className="container--basic" id="add-recipes">
                        <h3>The Basics</h3>
                        <h2>Find & Add More Recipes</h2>
                        <p>Want to personalize a little more?  Jonsin' for something particular? Need to use some chicken breasts?</p>
                        <p><strong>Pick from hundreds of recipes in the Menūd catalogue.</strong></p>
                        <ol>
                            <li>Tap any circle with a "+" sign then select any meal from the recipe catalogue.</li>
                            <li>Scroll through dishes, use the search bar, or tap the filter button to discover hundreds of meals curated by Menud's team of experts.</li>
                            <li>Tap the "+" to add to your recipe plan.</li>
                        </ol>
                    </div>
                    <div className="container--basic" id="review-groceries">
                        <h3>The Basics</h3>
                        <h2>Review Groceries</h2>
                        <p>Grocery lists save you time, money, and anxiety. Menūd builds a list for you and aggregates all the ingredients you need for the week.</p>
                        <p><strong>You can review aisle by aisle and check off items as you add them to your cart or if you don't plan to buy them:</strong></p>
                        <ol>
                            <li>Tap the grocery cart icon at the bottom.</li>
                            <li>Tap any category to see the items you need.</li>
                            <li>Tap an item to check it off your list.</li>
                            <li>Tap the category tab to close.</li>
                            <li>Add other items by tapping the bottom tab.</li>
                        </ol>
                    </div>
                    <div className="container--basic" id="discover-kitchens">
                        <h3>The Basics</h3>
                        <h2>Discover Kitchens</h2>
                        <p>Menūd partners with some incredible health and food gurus.  Each have a little sumthin' unique about their PlateLists.</p>
                        <p><strong>Get to know the Kitchens. See some stuff our partners recommend. Check out the variety of meals they recommend.</strong></p>
                        <ol>
                            <li>Tap the "Kitchen" icon (chef hat) at the bottom.</li>
                            <li>Tap a Kitchen that interests you.</li>
                            <li>Check out the profile, products, and dishes.</li>
                        </ol>
                    </div>
                    <div className="container--basic" id="add-favorites">
                        <h3>The Basics</h3>
                        <h2>Add Favorites</h2>
                        <p>Menūd partners with some incredible health and food gurus.  Each have a little sumthin' unique about their PlateLists.</p>
                        <p><strong>Save dishes to your 'kitchen drawer' for you to plan to your week.</strong></p>
                        <ol>
                            <li>Tap the cloche icon (second to the left that looks like a bell).</li>
                            <li>Scroll through dishes, use the search bar, or tap the filter button to discover hundreds of meals curated by Menud's team of experts.</li>
                            <li>Tap the heart icon and save to your 'kitchen drawer.'</li>
                            <li>Go back to your meal plan. Slide the drawer open. And drag-and-drop dishes to the day and meal of your choice.</li>
                        </ol>
                    </div>
                </div>
                <div className="container--mores" id="more">
                    <h1>A Litte More. A Little Better.</h1>
                    <div className="container--more" id="buy-groceries">
                        <h3>A Little More. A Little Better.</h3>
                        <h2>Buy Groceries</h2>
                        <p>Crazy day? No time to run to the store. No prob. On Menūd, you can shop for groceries from your couch, desk, or bathtub.</p>
                        <p><strong>Buy groceries and schedule delivery</strong></p>
                        <ol>
                            <li>Tap the grocery cart icon at the bottom.</li>
                            <li>Review your list and scratch off what you don't need.</li>
                            <li>Add staples to your list if you're out or running low.</li>
                            <li>Tap the "SHOP" button in the upper right.</li>
                            <li>Review your basket, and add to cart.</li>
                        </ol>
                    </div>
                    <div className="container--more" id="plans">
                        <h3>A Little More. A Little Better.</h3>
                        <h2>Share Recipes, Meals, & Plans</h2>
                        <p>Find something you love? Know a friend who is trying to eat healthy? Want to drop a hint to a housemate?</p>
                        <p><strong>Share recipes and other content in just a few taps.</strong></p>
                        <ol>
                            <li>Tap any share button (arrow with a box).</li>
                            <li>Send to a friend.</li>
                            <li>Your friend will receive a really cool link that will bring them straight into the content you shared.</li>
                        </ol>
                    </div>
                    <div className="container--more" id="portions">
                        <h3>A Little More. A Little Better.</h3>
                        <h2>Set of Change Portions</h2>
                        <p>Maybe you're cooking for a family, or just cooking for you. Or maybe you want to make enough for leftovers. We have you covered. When you update your portions, we update your recipes and your grocery list. #WickedSmaht</p>
                        <p><strong>Changes the number of portions from the meal plan screen.</strong></p>
                        <ol>
                            <li>Tap the meal plan icon on the bottom left.</li>
                            <li>Tap "Family of 2" in the upper right corner.</li>
                            <li>Set your family size; we will do the rest.</li>
                        </ol>
                    </div>
                    <div className="container--more" id="review-snacks">
                        <h3>A Little More. A Little Better.</h3>
                        <h2>Add or Review Snacks</h2>
                        <p>You can add snacks to your week.  Some recipe plans include snacks and desserts.  Because snacks and desserts are awesome!</p>
                        <p><strong>Check your snacks.</strong></p>
                        <ol>
                            <li>Tap the meal plan icon on the bottom left.</li>
                            <li>Snacks on the upper-center of the screen.</li>
                            <li>Review, remove, and add until your heart is content.</li>
                        </ol>
                    </div>
                    <div className="container--more" id="change-profile">
                        <h3>A Little More. A Little Better.</h3>
                        <h2>Create or Change a Profile</h2>
                        <p>We're always adding stuff to the profile screen to help make Menūd more personalized.</p>
                        <p><strong>Check your profile and create or update stuff.</strong></p>
                        <ol>
                            <li>Tap the "Kitchen" icon (chef hat) at the bottom.</li>
                            <li>Tap the Settings gear in the upper right corner.</li>
                            <li>Explore, change, and review until we get to know you better.</li>
                        </ol>
                    </div>
                </div>
                
                <Footer/>
            </div>
        )
    }
}