import React, { Component } from 'react'
import family from '../../../assets/home/card-images/family-friendly.png'
import gluten from '../../../assets/home/card-images/glutten-freen-min.png'
import paleo from '../../../assets/home/card-images/paleo-min.png'
import mediterranea from '../../../assets/home/card-images/mediterrean-diet-min.png'
import Card from './../../UI/Cards';
export default class MealPlans extends Component {
    render() {
        return (
            <div className="background-meal-plans">

                <div className=" d-none d-xl-block">
                    <div className="ml-content">
                        <div className="d-flex justify-content-around">
                            <div className="d-flex flex-column mt-5 p-2">
                                <h5 className="text-uppercase main-title-menud">
                                    Simply Personalized
                                 </h5>
                                <p className="black-paragraph-menud">
                                    Curated<br></br> meal plans <br></br> for  any<br></br> lifestyle.
                               </p>
                                <Card target="_blank" href="https://menud.app/sharon-palmer/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards mt-black-paragraph-menud" src="https://res.cloudinary.com/menud/image/upload/v1550186779/cva08twjdztzgptcqe7z.jpg"
                                    classimg="dimension-image-rectangular" alt="Veggie PlateList" />
                                <Card target="_blank" href="https://menud.app/quickmeals/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards mt-rectangular-cards" src="https://res.cloudinary.com/menud/image/upload/v1561150103/w3z22hldsr1gel2gzd0y.png"
                                    classimg="dimension-image-rectangular" alt="Quick Meal PlateList" />
                            </div>

                            <div className="d-flex flex-column mt-negative-rectangular-cards ">
                                <Card target="_blank" href="https://menud.app/family-friendly/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards" src={family} classimg="dimension-image-rectangular" alt="Keto PlateList" />

                                <Card target="_blank" href="https://menud.app/glutenfree/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards mt-rectangular-cards" src={gluten} classimg="dimension-image-rectangular" alt="Gluten-Free PlateList" />

                                <Card target="_blank" href="https://menud.app/erin-oprea/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards mt-rectangular-cards" src="https://res.cloudinary.com/dyfitjkxt/image/upload/v1537471398/Erin_Oprea_-_Standard_ctlyz4.jpg"
                                    classimg="dimension-image-rectangular" alt="Erin Oprea's PlateList" />
                            </div>

                            <div className="d-flex flex-column justify-content-around">

                                <Card target="_blank" href="https://menud.app/paleo/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards" src={paleo} classimg="dimension-image-rectangular" alt="Paleo PlateList" />

                                <Card target="_blank" classname="rectangular-cards" src={mediterranea}
                                    classimg="dimension-image-rectangular" alt="Mediterranea PlateList" />
                                <div className="text-center">
                                    <a target="_blank" rel="noopener noreferrer" href="https://menud.app/magic_link">
                                        <button className="btn-orange-menud text-btn-orange text-uppercase ">
                                            browse all meal plans
                                        </button>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/*Views for mobile phones and tablets */}
                <div className="d-xl-none">
                    <div className="d-flex flex-column ml-4">
                        <h5 className="text-uppercase main-title-menud mt-5">
                            Simply Personalized
                        </h5>
                        <p className="black-paragraph-menud">
                            Curated<br></br> meal plans <br></br> for  any<br></br> lifestyle.
                         </p>

                    </div>
                    <div className="d-flex ml-4 mt-5 over-flow-menu-d">
                        <div>
                            <Card target="_blank" href="https://menud.app/sharon-palmer/?%24identity_id=601133314333974417"
                                classname="rectangular-cards" src="https://res.cloudinary.com/menud/image/upload/v1550186779/cva08twjdztzgptcqe7z.jpg"
                                classimg="dimension-image-rectangular" alt="Veggie PlateList" />
                        </div>

                        <div className="ml-3">
                            <Card target="_blank" href="https://menud.app/quickmeals/?%24identity_id=601133314333974417"
                            classname="rectangular-cards" src="https://res.cloudinary.com/menud/image/upload/v1561150103/w3z22hldsr1gel2gzd0y.png" 
                            classimg="dimension-image-rectangular" alt="Quick Meal PlateList"/>
                        </div>

                        <div className="ml-3">
                            <Card target="_blank" href="https://menud.app/family-friendly/?%24identity_id=601133314333974417"
                               classname="rectangular-cards" src={family} classimg="dimension-image-rectangular" alt="Keto PlateList"/>  
                      </div>
                        <div className="ml-3">
                            <Card target="_blank" href="https://menud.app/glutenfree/?%24identity_id=601133314333974417" 
                             classname="rectangular-cards" src={gluten} classimg="dimension-image-rectangular" alt="Gluten-Free PlateList"/>
                        </div>
                        <div className="ml-3">
                            <Card target="_blank" href="https://menud.app/erin-oprea/?%24identity_id=601133314333974417"
                               classname="rectangular-cards"  src="https://res.cloudinary.com/dyfitjkxt/image/upload/v1537471398/Erin_Oprea_-_Standard_ctlyz4.jpg"
                               classimg="dimension-image-rectangular" alt="Erin Oprea's PlateList"/>
                        </div>
                        <div className="ml-3">
                            <Card target="_blank" href="https://menud.app/paleo/?%24identity_id=601133314333974417"
                            classname="rectangular-cards" src={paleo} classimg="dimension-image-rectangular" alt="Paleo PlateList"/>
                        </div>
                        <div className="ml-3">
                            <Card classname="rectangular-cards" src={mediterranea} classimg="dimension-image-rectangular" 
                            alt="Mediterranea PlateList" />
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}
