import React, { Component } from 'react'
import logo from '../../../assets/logos/logo float.png';
import MenuPhone from '../../UI/MenuMovil';
import {animateScroll as scroll } from 'react-scroll';
import MenuItem from '../../UI/MenuItem';
import { Link } from 'react-router-dom';

export default class MainSection extends Component {
    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
 }
    state = {
        BurguerMenuVisible: false,
  }

    openBurguerMenu = () => {
        this.setState({ BurguerMenuVisible: true })
    }

    closeBurguerMenu = () => {
        this.setState({ BurguerMenuVisible: false})
        
        scroll.scrollTo()

    }

    handleScroll = () => {
         if (window.screen.width > 994 && window.screen.width < 1365) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 703 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1365 && window.screen.width < 1417) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 654 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
    }
    render() {

        return (

            <div className="background-mainsection-brands">
                   <div className="d-flex flex-column flex-md-row justify-content-md-between">
                    <div className="ml-content-menud">
                        <div className="d-sm-none">
                            <a href="/#">
                                <img src={logo} alt="logo" onClick={this.openBurguerMenu} />
                            </a>
                        </div>
                        <div className=" d-none d-sm-block">
                            <Link to="/home"><img src={logo} alt="logo" /></Link> 
                        </div>
                    </div>
                    {/**Component that is displayed to show the mobile menu. */}
                    {this.state.BurguerMenuVisible &&
                        <MenuPhone close={this.closeBurguerMenu} />
                    } 
                     <MenuItem creator="text-white text-uppercase nav-link-menud"
                      brands="nav-link-menud-yellow text-uppercase" 
                      grocers="text-white text-uppercase nav-link-menud" 
                      href="https://menud.app/magic_link"/>

                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between  m-main-card-menud mt-content-menud">
                    <div className="ml-content-menud" ref={this.textScroll}>
                        <h5 className="main-title-menud text-uppercase mt-5 ml-4 ">
                            FOR BRANDS
                        </h5>
                        <div className="ml-4">
                            <p className=" main-paragraph-menud">
                                Shop for a week's worth of groceries in 30 seconds.
                         </p>
                        </div>

                        <div>
                            <button className="text-uppercase btn-menud main-color-button mt-3 mt-md-5 ml-4"> email cta?</button>
                        </div>

                    </div>
                    <div className="d-flex flex-column over-flow-menu-d margins-content-card-rectangle"
                        onScroll={this.handleScroll.bind(this)}>
                        <div className="ml-card-rectangle mt-5 mt-lg-0" ref={this.card}>
                            <div className="rectangle-card-menud" ></div>

                        </div>
                        <div className="position-card-brands">
                            <div className="card-position shadow"></div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}
