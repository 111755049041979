import React, { Component } from 'react'

export default class Card extends Component {
    render() {
        return (
             <div className={this.props.classname}>
                <a target={this.props.target} href={this.props.href}>
                     <img src={this.props.src} alt={this.props.alt} className={this.props.classimg} />
                </a>
                 {this.props.children}          
            </div>
        )
    }
}
