import React, { Component } from 'react'
import Footer from '../UI/Footer';

export default class Jobs extends Component {
    render() {
        return (
            <div className="content--jobs">
                <div className="hero">
                    <h1>Menūd</h1>
                </div>
                <p>
                    Thanks for stopping by... Check below for openings on TeamMenud.
                </p>
                <p>
                    We're hungry for innovators who are eager to evolve the world of healthy eating. Make sure to let us know how you would be a #1 contributor. The first question we will ask during the interview: what, specifically, will you contribute to making Menud great?
                </p>
                <p className="jobs--callout">WE'RE SORRY, BUT EMAILS FROM RECRUITERS & AGENCIES WILL BE IGNORED. Please only consider reaching out to us if you ‍w‍‍illing to work in Nashville, TN.</p>
                <h2>Jobs</h2>
                <div className="jobs--job">
                    <div className="job--content">
                        <h4>Junior Front-End Developer</h4>
                        <p>Ready to gain real-world experience while helping change the way people shop for groceries? Drop us a line.</p>
                        <a href="mailto:greg@menud.co">Get In Touch</a>
                    </div>
                </div>
                <div className="jobs--job">
                    <dev className="job--content">
                        <h4>Mid-Senior React Native Developer</h4>
                        <p>Do you thrive on strategy and execution? If you enjoy solving multi-platform problems at scale, let us know.</p>
                        <a href="mailto:greg@menud.co">Get In Touch</a>
                    </dev>
                </div>
                <Footer/>             
            </div>
        )
    }
}