import React, { Component } from 'react'
import logo from '../../assets/logos/logoMenu.png';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import twitter from '../../assets/icons/twitter.svg';
import close from '../../assets/icons/close.svg';
import { Link } from 'react-router-dom';

export default class MenuPhone extends Component {
    state = {
        BurguerMenuVisible: false,
    }

    render() {

        return (
           

                <div className="background-menu-phone">
                    <div className="d-sm-none">
                        <div className="d-flex flex-column">
                            <div className="mt-4 ml-5">
                                <img src={close} alt="Close" onClick={this.props.close} className="close-menu-phone"/>

                            </div>
                            <div className="d-flex flex-column align-items-center mt-3">
                                <Link to="/home"><img src={logo} alt="logo" /></Link> 
                                <div className=" d-flex flex-column align-items-center margin-top-item-menu">
                                    <a href="/creator" className="text-white text-uppercase nav-link-menud">
                                        Creators
                                </a>
                                    <a href="/brands" className=" text-white nav-link-menud text-uppercase mt-4 p-1">
                                        brands
                              </a>
                                    <a href="/grocers" className=" text-white text-uppercase nav-link-menud mt-4 p-1">
                                        grocers
                              </a>

                                </div>
                                <div className="d-flex flex-column margin-top-item-menu">
                                    <a target="_blank" rel="noopener noreferrer" href="https://menud.app/magic_link">
                                        <button
                                            className="text-white text-uppercase btn-orange-download-menud text-white-download-menud">
                                            download menud
                                </button>
                                    </a>
                                </div>
                                <div className="d-flex flex-column align-items-center margin-top-item-menu">
                                    <p className="text-white-item-menu">
                                        JOIN TeamMenud</p>
                                    <p className="text-white-item-menu">
                                        TERMS OF SERVICE</p>
                                </div>

                                <div className="container mt-4">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div>
                                            <img src={instagram} alt="Instagram" />
                                        </div>
                                        <div className="ml-4">
                                            <img src={twitter} alt="Twitter" />
                                        </div>
                                        <div className="ml-4">
                                            <img src={facebook} alt="Facebook" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

          

        )
    }
}