import React, { Component } from 'react'
import collaborate from '../../../assets/icons/collaborate.svg';
import target from '../../../assets/icons/target.svg';
import execute from '../../../assets/icons/execute.svg';
import measure from '../../../assets/icons/measure.svg';

export default class TurnkeySolution extends Component {
    render() {
        return (
            <div className="background-turnkey">
                <div className="d-flex flex-column align-items-center">
                    <h5 className="title-white mt-5 p-4 text-center">Turnkey solution <br />within 1 week</h5>
                </div>

                <div className="d-none d-xl-block">
                    <div className="d-flex flex-column flex-md-row justify-content-around mt-2 p-5">
                        <div className="d-flex flex-column align-items-center text-center">
                            <img src={collaborate} alt="Collaborate"  />
                            <h5 className="main-title-menud text-uppercase mt-3"> Collaborate</h5>
                            <p className="icon-white">
                                Decide the type of<br></br> customers are you<br></br> trying to reach.
                             </p>
                        </div>

                        <div className="d-flex flex-column align-items-center text-center">
                            <img src={target} alt="Target"  />
                            <h5 className="main-title-menud text-uppercase mt-3">
                                Target
                            </h5>
                            <p className="icon-white">
                                Select influencers and<br></br> content to attract<br></br> the right shopper.
                           </p>
                        </div>

                        <div className="d-flex flex-column align-items-center text-center">
                            <img src={execute} alt="Target"  />
                            <h5 className="main-title-menud text-uppercase mt-3">
                                Execute
                             </h5>
                            <p className="icon-white">
                                Use influencer and <br></br>paid marketing to<br></br> drive performance.
                            </p>
                        </div>

                        <div className="d-flex flex-column align-items-center text-center">
                            <img src={measure} className="mt-2" alt="Target"/>
                            <h5 className="main-title-menud text-uppercase mt-3">
                                Measure
                            </h5>
                            <p className="icon-white">
                                Set KPIs to track <br></br>conversion, volume, <br></br>and stickiness.
                             </p>
                        </div>
                    </div>
                </div>

                {/*Icon view for cell phones and tablets.*/}
                <div className="d-xl-none">
                    <div class="d-flex justify-content-around mt-5">
                        <div className="d-flex flex-column align-items-md-center">
                            <img src={collaborate} alt="Collaborate" className="ml-4 ml-sm-0 dimension-icon" />
                            <h5 className="main-title-menud text-uppercase mt-4 text-center"> Collaborate</h5>
                            <p className="icon-white text-center">
                                Decide the type of<br></br> customers are you <br></br>trying to reach.
                            </p>
                        </div>
                        <div className="d-flex flex-column align-items-md-center">
                            <img src={target} alt="Target" className="ml-4 ml-sm-0 dimension-icon" />
                            <h5 className="main-title-menud text-uppercase mt-4 text-center">
                                Target
                            </h5>
                            <p className="icon-white text-center">
                                Select influencers and<br></br> content to attract the <br></br>right shopper.
                           </p>

                        </div>
                    </div>
                    <div class="d-flex justify-content-around mt-5">
                        <div className="d-flex flex-column align-items-md-center">
                            <img src={execute} alt="Execute" className=" ml-4 ml-sm-0 dimension-icon"/>
                            <h5 className="main-title-menud text-uppercase mt-3 text-center"> Execute</h5>
                            <p className="icon-white text-center"> 
                            Use influencer and<br></br> paid marketing<br></br> to drive performance.
                           </p>
                        </div>
                        <div className="d-flex flex-column align-items-md-center">
                            <img src={measure} alt="Measure" className=" ml-4 ml-sm-0 dimension-icon mt-2"/>
                            <h5 className="main-title-menud text-uppercase mt-4 text-center">  Measure</h5>
                            <p className="icon-white text-center">
                                Set KPIs to track <br></br>conversion, volume, <br></br>and stickiness.
                             </p>
                        </div>
                    </div>
                </div>


                <div className="d-flex justify-content-center">
                    <button className="btn-yellow-transparent text-yellow text-uppercase mt-5" onClick={ () => window.location.href="mailto:team@menud.co" }>
                        Get in Touch
                    </button>
                </div>

            </div>
        )
    }
}
