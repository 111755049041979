import React, { Component } from 'react'

export default class DownloadMenud extends Component {
    render() {
        return (
            <div className="background-download-menud">
                <div className="d-flex flex-column mx-auto my-auto text-center">
                    <div>
                        <p className="italic-text-white-menud" >
                            Never ask  <br></br>
                            “what’s for dinner”<br></br> again.
                         </p>
                      </div>
                       <div>
                       <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/menud/id1148238003" className="link-menud">
                            <button  className="btn-yellow-transparent text-yellow text-uppercase mt-5">
                                    Download menuD
                            </button>
                       </a>
                        
                    </div>
                </div>
            </div>
        )
    }
}
