import React, { Component } from 'react'

export default class TurnkeyInteractive extends Component {
    render() {
        return (
            <div className="background-turnkey-iteractive">
                <div className="d-flex flex-column mx-auto my-auto text-center">
                    <h5 className="font-weight-orange-menud  text-center text-uppercase">A turn-key interactive experience</h5>
                     <p className="white-title-menu mt-5"> For less than 50% of the cost of one<br></br> software developer.</p> 
                    <div className="text-center">
                        <button className="btn-yellow-transparent text-yellow text-uppercase mt-5" onClick={ () => window.location.href="mailto:team@menud.co" }>
                            Get started
                         </button>

                    </div>

                </div>
            </div>
        )
    }
}
