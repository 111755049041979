import React, { Component } from 'react'
import plan from '../../../assets/grocers/screens/plan-1-min.png';
import mealplans from '../../../assets/brands/screen/meal-plans-brands.svg';

export default class Curation extends Component {
    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
       
    }

    handleScroll = () => {
        if(window.screen.width>639 && window.screen.width<=737){
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 435 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
             this.textScroll.current.style = this.initalOpacity
            
        }

        if (window.screen.width > 767 && window.screen.width < 1024) {
            
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 487 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1023 && window.screen.width < 1366) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 555 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
       
    }
    render() {
        return (
            <div className="background-curation">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                    <div className="d-flex flex-column my-sm-auto margin-left-content margins-content-small-text p-sm-4  p-xl-0" ref={this.textScroll}>
                        <h5 className="text-uppercase title-black">
                            Curation
                        </h5>
                        <p className="title-white">
                            Dinner <br></br>solved.
                        </p>
                        <p className="text-black-menu-cards mt-4">
                            In one tap, a shopper can grab a meal plan that fits with their life. 
                            With dozens of meal plans to choose from, Menūd creates a curated experience for any shopper.<br/><br/>
                          

                            Each meal plan is interactive with dynamic recipe selection. Recipes can be swapped,
                             added or removed. 
                            Shoppers can adjust serving sizes on the fly.
                        </p>
                    </div>
                    <div className=" d-flex  flex-column flex-sm-row mt-angle-card m-negative-card over-flow-menu-d" onScroll={this.handleScroll.bind(this)}>
                        <div className="d-flex ml-content-cards ml-sm-content-cards">
                            <div ref={this.card}>
                                <div className="card-menud shadow" >
                                    <img src={mealplans} className="dimensions-images" alt="Meal-plans" />
                                </div>
                            </div>
                            <div className="ml-3 mr-4">
                                <div className="card-menud shadow">
                                    <img src={plan} className="dimensions-images" alt="Recipe" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="mt-1  mr-4">
                    </div>
                </div>
            </div>
        )
    }
}
