import React, { Component } from 'react'
import cleaneating from '../../../assets/creators/screen/clean_eating.png';
import creator from '../../../assets/creators/screen/creator_lose_weight.png';

export default class WhiteLabel extends Component {
    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
       
    }

    handleScroll = () => {

        if(window.screen.width>639 && window.screen.width<=737){
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 435 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
             this.textScroll.current.style = this.initalOpacity
            
        }
        if (window.screen.width > 767 && window.screen.width < 1024) {
            
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 487 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1023 && window.screen.width < 1366) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 555 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
       
    }
    render() {
        return (
            <div className="background-white-label">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                    <div className="d-flex flex-column my-sm-auto margin-left-content margins-content-small-text p-sm-4  p-xl-0" ref={this.textScroll}>
                        <h5 className="text-uppercase title-black">
                            White-Label App
                        </h5>
                        <p className="title-white">
                            Interactive, dynamic, <br />and smart mobile app
                        </p>
                        <p className="text-black-menu-cards mt-4">
                            Recipes across all publications are discoverable and interactive.
                            Users can load and customize recipes plans. 
                            Recipe plans build a grocery list. Grocery list is shoppable online in 10K US grocery stores.
                        </p>
                        <p className="plankk-shoutout">
                            Clean Eating app built with out platform partner, Plankk Technologies.
                        </p>
                    </div>
                    <div className=" d-flex  flex-column flex-sm-row mt-angle-card m-negative-card over-flow-menu-d" onScroll={this.handleScroll.bind(this)} >
                        <div className="d-flex ml-content-cards ml-sm-content-cards">
                            <div  ref={this.card}>
                                <div className="card-menud shadow" >
                                    <img src={cleaneating} className="dimensions-images" alt="Clean-eating" />
                                </div>
                            </div>
                            <div className="ml-3 mr-4">
                                <div className="card-menud shadow">
                                    <img src={creator} className="dimensions-images" alt="Creator-lose-weight" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="mt-1  mr-4">
                    </div>
                </div>
                <div className="d-flex flex-column align-items-sm-center mt-5 ml-4 ml-sm-0 ">
                    <h5 className="font-weight-white font-weight-sm-white">
                        Revenue Opportunities
                    </h5>
                </div>
                <div className="container">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-center mt-5 justify-content-sm-around ml-3 ml-sm-0">
                        <div className="d-flex flex-column align-items-md-center">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">
                                Self-Product<br></br> Placement
                            </h5>
                            <div className="d-none d-md-block">
                                <p className=" box-paragraph-menu  mt-3">
                                    Recipes and grocery lists can<br></br> call for specific, branded<br></br> products.
                             </p>

                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu text-sm-center">
                                    Recipes and grocery lists can call for specific, branded products.
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-column align-items-sm-center mt-4 mt-sm-0 ml-sm-3 ml-md-0">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">
                                App <br></br>Subscriptions
                             </h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu mt-3">
                                    Mobile app can be offered <br></br>as a premium, monthly<br></br> subscription.
                             </p>

                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu text-sm-center">
                                    Mobile app can be offered as a premium, monthly subscription.
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-column align-items-sm-center mt-4 mt-sm-0 ml-sm-3 ml-md-0">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">
                                Affiliate <br></br>Connections
                          </h5>
                            <div className="d-none d-md-block">
                                <p className=" box-paragraph-menu box-sm-paragraph-menu mt-3">
                                    Publications and products.<br></br> Link to eCommerce <br></br>channels.
                                </p>

                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu text-sm-center">
                                Publications and products. Link to eCommerce  channels. 
                                </p>
                            </div>


                        </div>

                    </div>

                </div>


            </div>
        )
    }
}
