import React, { Component } from 'react'
import grocery from '../../../assets/grocers/screens/plan-2.png';
import recipe from '../../../assets/brands/screen/recipe.png';

export default class ShopperJourney extends Component {
    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
        this.moveCard = React.createRef();
    }

    handleScroll = () => {

        
        if (window.screen.width > 767 && window.screen.width < 1024) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 91 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            this.textScroll.current.style = this.initalOpacity
           console.log(this.initalOpacity)
            console.log(x)
        }
        if (window.screen.width >1023 && window.screen.width < 1200) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 81 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            this.textScroll.current.style = this.initalOpacity
           console.log(this.initalOpacity)
            console.log(x)
        }


        if (window.screen.width > 1499 && window.screen.width < 2000) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 250 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            this.textScroll.current.style = this.initalOpacity
           console.log(this.initalOpacity)
            console.log(x)
        }
        if (window.screen.width > 1200 && window.screen.width < 1500) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 186 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            console.log(this.initalOpacity)
            this.textScroll.current.style = this.initalOpacity
            console.log(x)
        }
    }
    render() {
        return (
            <div className="background-shooperjourney">
                <div className="d-none d-md-block">
                    <div className="d-flex flex-column flex-md-row justify-content-md-between flex-nowrap mt-5 mr-xl-0">
                        <div className=" d-flex order-1 order-xl-0 position-relative over-flow-menu-d m-negative-card-menud "
                         onScroll={this.handleScroll.bind(this)}>
                            <div className="d-flex">

                                <div className="invisible margin-content-cards">
                                    <div className="card-menud"></div>
                                </div>

                                <div ref={this.moveCard}>
                                    <div className="card-menud shadow-sm">
                                        <img src={grocery} className="dimensions-images" alt="Grocery" />
                                    </div>
                                </div>

                                <div className="ml-4">
                                    <div className="card-menud shadow-sm" ref={this.card}>
                                        <img src={recipe} className="dimensions-images" alt="recipe" />
                                    </div>
                                </div>

                                <div className="invisible margin-left-invisble-card">
                                    <div className="card-menud shadow-sm">
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                        <div className="d-flex flex-column order-0 order-md-1 m-negative-paragraph mr-auto-menud position-relative" 
                        ref={this.textScroll}>
                             <h5 className="text-uppercase main-title-menud mt-5 mt-md-0"> Shopper Journey</h5> 
                             <p className="black-paragraph-menud"> Brand<br></br> awareness at <br></br>every step.</p>  
                             <p className="text-black-menu-cards">
                                Each meal plan is interactive with dynamic recipe selection.
                                 Recipes can be swapped, added or removed. Shoppers can adjust serving sizes on the fly.
                            </p>  

                        </div>

                    </div>
                </div>
                {/*  View of cards for cell phones and tablets.*/}
                <div className="d-md-none">
                    <div className="d-flex flex-column flex-xl-row mt-5">

                        <div className=" d-flex m-negative-card-menud  mr-0 mr-xl-5  order-1 order-xl-0 over-flow-menu-d">
                            <div className="d-flex margin-content-cards-menud">
                                <div className="">
                                    <div className="card-menud shadow-sm">
                                        <img src={grocery} className="dimensions-images" alt="Grocery" />
                                    </div>

                                </div>
                                <div className="ml-4">
                                    <div className="card-menud shadow-sm">
                                        <img src={recipe} className="dimensions-images" alt="Recipe" />
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="d-flex flex-column ml-4 ml-lg-5 order-0 order-xl-1">
                            <h5 className="text-uppercase main-title-menud mt-5 mt-xl-0"> Shopper Journey</h5>
                            <p className="black-paragraph-menud"> Brand<br></br> awareness at <br></br>every step.</p>
                            <p className="text-black-menu-cards">
                                Each meal plan is interactive with dynamic recipe selection.
                                Recipes can be swapped, added or removed. Shoppers can adjust serving sizes on the fly.
                          </p>
                        </div>
                    </div>
                </div>


                <div className="d-flex justify-content-end justify-content-md-start">
                    <div className="mt-4 ml-0 ml-md-4 mr-3 mr-md-0">
                    </div>
                </div>

                <div className="d-flex flex-column flex-xl-row mt-5">
                    <div className="d-flex flex-column margin-top-text margin-left-text">
                        <h5 className="main-title-menud text-uppercase  mt-5"> WORKING WITH BRANDS</h5>
                        <p className="black-paragraph-menud mr-5"> For mobile<br></br>& desktop.</p>
                        <p className="text-black-menu-cards mt-4 mr-5">
                            Menud operates as a mobile app, and API, and a custom micro-site. Multiple platforms allows frictionless
                             customer acquisition without sacrificing product experience.
                        </p>
                    </div>

                    <div className=" d-flex flex-column  over-flow-menu-d ml-xl-auto mt-4 ">
                        <div className="">
                            <div className="rectangle-card-menud"></div>
                         </div>

                        <div className="position-card-shopper">
                            <div className="card-position shadow"></div>
                        </div>
                      </div>

                </div>
                <div className="d-flex flex-column justify-content-sm-center align-items-sm-center mt-box-menud">
                    <h5 className=" text-sm-center black-paragraph-menud ml-4 ml-lg-0">
                        Building for<br></br> the customer
                    </h5>
                    <p className="text-sm-center paragraph-shopper mt-4 ml-4 ml-lg-0">
                        Shoppable recipes limit the customers to a single site’s content and have difficulty aggregating carts.<br></br>
                        Menūd builds full grocery lists from smart, personalized recipe plans.
                    </p>
                </div>
                <div className="container">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-center mt-5 justify-content-sm-around ml-3 ml-sm-0">
                        <div className="d-flex flex-column align-items-sm-center mr-sm-3 mr-md-0">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">
                                Bigger <br></br>Baskets
                            </h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu mt-2">
                                    A week of recipes<br></br> contains more items<br></br> than a single recipe.
                                </p>
                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu">
                                    Input recipes using Menūd’s intelligent and user-friendly recipe management system.
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-column mt-5 mt-sm-0">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">Cross <br></br>Marketing</h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu mt-2">
                                    Products in all<br></br> categories and brands<br></br> incorporated into plan.
                               </p>
                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu">
                                    Menūd can import recipes from your database.
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-column mt-5 mt-sm-0 ml-sm-3 ml-md-0">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center"> New <br></br>Products</h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu mt-2">
                                    Introduce products by<br></br> including into the<br></br> shopper’s plan.
                             </p>
                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu">
                                    Have recipes scraped, codified, and structured using natural language processing and machine learning.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
