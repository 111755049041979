import React, { Component } from 'react'
import keto from '../../../assets/grocers/card-images/keto-min.png';
import veggie from '../../../assets/grocers/card-images/veggie-min.png';
import paleo from '../../../assets/grocers/card-images/paleo-min.png';
import gluten from '../../../assets/grocers/card-images/glutten-freen-min.png';
import Card from '../../UI/Cards';
export default class MealDecision extends Component {
    render() {
        return (
            <div className="background-meal-decision">
                <div className=" d-none d-xl-block">
                    <div className="d-flex justify-content-around">
                        <div className="d-flex flex-column mt-5 ml-5 p-2 ml-content">
                            <h5 className="text-uppercase main-title-menud" >Mindfully Market</h5>
                            <p className="black-paragraph-menud">Introduce exciting products in the most meaningful way.</p>
                            <p className="text-black-menu-cards mt-4">
                                Showcase new brands and products in your shopper’s attention channel.  Align products with diet-specific recipe plans to connect to the shopper.     
                            </p>
                            <Card classname="rectangular-cards mt-black-paragraph-menud shadow-sm"
                                src={veggie} classimg="dimension-image-rectangular" alt="Veggie" />
                        </div>

                        <div className="d-flex flex-column mt-negative-rectangular-cards">
                            <div>
                                <Card target="blank" href="https://menud.app/family-friendly/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards shadow-sm" src={keto} classimg="dimension-image-rectangular" alt="Keto" />

                            </div>
                            <div className="mt-rectangular-cards">
                                <Card target="blank" href="https://menud.app/glutenfree/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards shadow-sm" src={gluten} classimg="dimension-image-rectangular" alt="Gluten-Free PlateList"/>
                             </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div>
                                <Card target="blank" href="https://menud.app/paleo/?%24identity_id=601133314333974417"
                                    classname="rectangular-cards shadow-sm" src={paleo} classimg="dimension-image-rectangular" alt="Paleo PlateList"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/*View of cards for cell phones and tablets.*/}
                <div className="d-xl-none">
                    <div className="d-flex flex-column ml-4">
                        <h5 className="text-uppercase main-title-menud mt-5">
                            MEAL DECISIONS
                        </h5>
                        <p className="black-paragraph-menud">
                            Intercepting<br></br> the pain<br></br> point.
                         </p>
                        <p className="text-black-menu-cards mt-4">
                            Lorem ipsum dolor sit amet,<br></br> consectetur adipisicing elit, sed do<br></br> eiusmod tempor incididunt
                                ut labore <br></br>et dolore magna aliqua.
                                Ut enim ad<br></br> minim veniam, quis nostrud<br></br> exercitation ullamco laboris nisi ut aliquip.
                        </p>

                    </div>
                    <div className="d-flex ml-4 mt-5 over-flow-menu-d">
                        <div>
                            <Card target="blank" href="https://menud.app/family-friendly/?%24identity_id=601133314333974417"
                                classname="rectangular-cards shadow-sm" src={keto} classimg="dimension-image-rectangular" alt="Keto PlateList" />
                        </div>
                        <div>
                            <Card classname="rectangular-cards shadow-sm ml-3" src={veggie} classimg="dimension-image-rectangular" alt="Veggie" />
                        </div>
                        <div>
                            <Card target="blank" href="https://menud.app/paleo/?%24identity_id=601133314333974417"
                                classname="rectangular-cards shadow-sm ml-3" src={paleo} classimg="dimension-image-rectangular" alt="Paleo PlateList" />
                        </div>
                        <div>
                            <Card target="blank" href="https://menud.app/glutenfree/?%24identity_id=601133314333974417"
                            classname="rectangular-cards shadow-sm ml-3"  src={gluten} classimg="dimension-image-rectangular" alt="Gluten-Free PlateList"/>
                        </div>     

                    </div>
                </div>



                <div className="d-flex justify-content-center mt-5">
                    <h5 className="text-uppercase main-title-menud">
                        By the numbers

                    </h5>

                </div>
                <div className="container mt-5 ">
                    <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">

                        <div className="box-menud">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div className=" mt-4 mt-lg-5 p-1">
                                    <h5 className="box-title-menud"> 76%</h5>
                                </div>
                                <div className="text-center box-paragraph-menud">
                                    <p>Families who don’t have<br></br> dinner plans at 4PM<br></br> each evening.</p>
                                </div>
                            </div>
                        </div>
                        <div className="box-menud mt-3 mt-md-0 ml-md-3">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div className="mt-4 mt-lg-5 p-1">
                                    <h5 className="box-title-menud"> 132</h5>
                                </div>
                                <div className="text-center box-paragraph-menud">
                                    <p>Aggregated hours per year <br></br>that couples spend <br></br>deciding what to eat.</p>
                                </div>
                            </div>
                        </div>
                        <div className="box-menud mt-3 mt-md-0 ml-md-3">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div className="mt-4 mt-lg-5 p-1">
                                    <h5 className="box-title-menud">31%</h5>
                                </div>
                                <div>
                                    <p className="text-center box-paragraph-menud">Millennials who say they<br></br> dislike choosing what to <br></br>cook above all else.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
