import React, { Component } from 'react'
import shopper from '../../../assets/icons/shopper-segmentation.svg';
import actionable from '../../../assets/icons/actionable-content.svg';
import proven from '../../../assets/icons/proven-engagement.svg';
export default class Develop extends Component {
    render() {
        return (
            <div className="background-develop">
                <div className="d-flex flex-column mx-auto my-auto text-center">
                    <div className="d-flex flex-column align-items-center text-center">
                        <h5 className="title-white">Develop your <br></br>community.</h5>
                         <p className="paragraph-white-develop mt-4 mb-5 text-center">
                            Recipe marketing inspires shoppers but doesn’t create a lasting relationship.
                             Menūd recipe plans allows brands to<br></br> build campaigns that engage the shopper at their pain point or interest.
                         </p> 
                    </div>

                    <div className="container mt-5">
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-center justify-content-sm-around mt-1 ml-2 ml-sm-0">
                            <div className="d-flex flex-sm-column align-items-sm-center mr-sm-4 mr-xl-0">

                                <img src={shopper} alt="Shooper" />
                                <div className="d-none d-sm-block">
                                    <div className="d-flex flex-column ml-4 ml-sm-0">
                                        <h5 className="main-title-menud text-uppercase mt-5"> Shopper <br></br>Segmentation</h5>
                                        <p className="icon-white">
                                            Health focus? <br></br>Busy moms?<br></br>Cheap dinners?
                                      </p>
                                    </div>
                                </div>
                                <div className="d-sm-none">
                                    <div className="d-flex flex-column ml-4">
                                        <h5 className="title-orange-icon text-uppercase">Shopper Segmentation</h5>
                                        <p className="icon-white-develop">
                                            Decide the type of customers are you trying to reach.
                                      </p>
                                    </div>

                                </div>

                            </div>
                            <div className="d-flex flex-sm-column align-items-sm-center mt-5 mt-sm-0 ml-sm-5 ml-xl-0">
                                <img src={actionable} alt="Actionable" />
                                <div className="d-none d-sm-block">
                                    <div className=" d-flex flex-column  ml-4 ml-sm-0">

                                        <h5 className="main-title-menud text-uppercase mt-5"> Actionable <br></br>Content</h5>
                                        <p className="icon-white">
                                            Solve the shopper’s <br></br>problem. Don’t just inspire<br></br> them to cook.
                                         </p>
                                    </div>
                                </div>
                                <div className="d-sm-none ml-4">
                                    <h5 className="title-orange-icon text-uppercase">Actionable Content</h5>
                                    <p className="icon-white-develop">
                                        Solve the shopper’s problem. Don’t just inspire them to cook.
                                    </p>
                                </div>

                            </div>
                            <div className="d-flex flex-sm-column align-items-center mt-5 mt-sm-0 ml-sm-5 ml-xl-0">
                                <img src={proven} alt="Proven" />
                                <div className="d-none d-sm-block">
                                    <div className="d-flex flex-column ml-4 ml-sm-0">
                                        <h5 className="main-title-menud text-uppercase mt-5"> Proven <br></br>Engagement</h5>
                                        <p className="icon-white">
                                            Check in on shoppers.<br></br> Create a dialogue.<br></br> Trigger re-engagement.
                                        </p>
                                    </div>

                                </div>
                                <div className="d-sm-none ml-4">
                                    <h5 className="title-orange-icon text-uppercase"> Proven Engagement</h5>
                                    <p className="icon-white-develop">
                                        Check in on shoppers.<br></br> Create a dialogue.<br></br>Trigger re-engagement.
                                    </p>
                                </div>


                            </div>
                        </div>

                    </div>




                    <div className="d-flex justify-content-center">
                        <button className="btn-yellow-transparent text-yellow text-uppercase mt-5">
                            Get Started
                         </button>
                    </div>



                </div>




            </div>
        )
    }
}
