import React, { Component } from 'react';
import adding from '../../../assets/home/screens/Adding.png';
import open from '../../../assets/home/screens/Open.png';
import logo from '../../../assets/logos/logoMenu.png';

export default class MainSection extends Component {
    constructor(props) {
        super(props);
         this.textScroll = React.createRef();
         this.card = React.createRef();
         }
         handleScroll = () => {
             if(window.screen.width>1023 && window.screen.width<=1366){
                let x = this.card.current.getBoundingClientRect().x;
                let opacity = x / window.innerWidth;
                this.initalOpacity = x >= 600 ? `opacity: 1` : `opacity: ${opacity}`
                console.log(this.initalOpacity)
                console.log(x)
                this.textScroll.current.style = this.initalOpacity
             }
            if (window.screen.width > 1365 && window.screen.width <= 1520) {
                let x = this.card.current.getBoundingClientRect().x;
                let opacity = x / window.innerWidth;
                this.initalOpacity = x >= 826 ? `opacity: 1` : `opacity: ${opacity}`
                console.log(this.initalOpacity)
                this.textScroll.current.style = this.initalOpacity
            }
        }
    render() {
        return (
            <div>
                <div className="background-main-home">
                    <div className="d-flex flex-column flex-md-row justify-content-md-between flex-nowrap">
                        <div className="d-flex flex-column mt-content-p position-relative ">
                            <div className="ml-main" ref={this.textScroll}>
                                <div className="mb-5 mt-5 mt-mb-0">
                                    <img src={logo} alt="logo-home" />

                                </div>
                                <h5 className="mt-5 main-title-menud text-uppercase">
                                    Unthinkablty fast
                               </h5>
                                <p className="main-paragraph-menud">
                                    Shop for a week's worth of groceries in 30 seconds.
                                </p>
                                <a target="_blank"  rel="noopener noreferrer" href="https://plan.menud.co">
                                    <button className="btn-menud text-uppercase main-color-button mt-3 mt-md-5">
                                         Start Saving Time    
                                    </button> 
                                 </a>
                         </div>
                        </div>

                          <div className="d-flex  flex-row m-main-card-menud over-flow-menu-d negative-margin-card-content" onScroll={this.handleScroll.bind(this)}>
                            <div className="d-flex ml-content-card">
                                <div>
                                    <div className="p-2" ref={this.card}>
                                        <div className="card-menud shadow">
                                            <img src={adding} className="dimensions-images" alt="Adding" />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="card-menud shadow" >
                                        <img src={open} className="dimensions-images" alt="Open" />
                                    </div>
                                </div>
                             </div>
                           </div>  
                         </div>

                        <div className="d-flex flex-column align-items-end position-relative mr-4 mt-1 z-index-arrow">
                            <div>
                            </div>
                         </div>

                     </div>
                 </div>

        )
    }
}
