import React, { Component } from 'react';
import largeAddRecipe from '../../../assets/creators/screen/largeAddRecipe.jpg';
import smallRecipes from '../../../assets/creators/screen/smallRecipes.png'

export default class Microsite extends Component {
    render() {
        return (
            <div className="background-microsite">
                <div className="d-flex flex-column flex-xl-row justify-content-xl-between">
                    <div className="d-flex flex-column order-1 order-xl-0">
                        <div className=" over-flow-menu-d margin-microsite">
                            <div className="">
                                <div className="rectangle-card-menud shadow" >
                                    <img src={largeAddRecipe} alt="Add recipe to plan on deskotp"/>
                                </div>
                            </div>

                            <div className="position-card-menud">
                                <div className="card-position shadow">
                                    <img src={smallRecipes} alt="Mobile microsite browsing all recipes."/>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="d-flex flex-column margin-microsite-paragraphs order-0 order-xl-1 ml-5">
                        <h5 className="black-paragraph-menud">Microsite<br></br>
                            for Web<br></br>
                            & Desktop.
                        </h5>
                        <p className="text-black-menu-cards mt-3">
                            Rather than driving your audience off your site and to an app, Menud brings the app functaiolity to your site. Not only does this drastically reduce the user’s friction to get started, but it allows you to keep your audience in front of your content and your marketing at all times.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
