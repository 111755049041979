import React, { Component } from 'react'
import recipe from '../../../assets/icons/recipe-plans.png';
import recipe2 from '../../../assets/icons/recipe-plans-1.png';
import recipe3 from '../../../assets/icons/recipe-plans-2.png'

export default class Savetime extends Component {
    render() {
        return (
            <div className="background-savetime">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column align-items-start align-items-sm-center">
                        <div className="d-none d-sm-block mt-title-white">
                            <h5 className="white-title-menu  mb-3 mb-md-5">
                                3 ways to save time.
                          </h5>
                        </div>
                        <div className="d-sm-none mt-title-white ml-4">
                            <h5 className="white-title-menu  mb-3 mb-md-5">
                                3 ways to<br></br> save time.
                          </h5>
                        </div>

                    </div>

                    <div className="">
                        <div className="container mt-5">
                            <div className="d-flex flex-column flex-sm-row justify-content-around">

                                <div className=" d-flex flex-sm-column text-sm-center mb-3">
                                    <div className="mr-2 mr-sm-0">
                                        <img src={recipe} className="dimension-sm-icon" alt="Recipe" />
                                    </div>

                                    <h5 className="text-uppercase mt-3 icon-title ml-4 ml-sm-0">
                                        curated <br></br>meal plans
                                    </h5>
                                    <div className="d-none d-sm-block">
                                        <p className="mt-2 text-center box-paragraph-menu">
                                            Dozens of expertly<br></br> built recipe plans<br></br> that are 100% customizable.
                                      </p>

                                    </div>
                                </div>
                                <div className="d-flex flex-sm-column text-sm-center mt-5 mb-3 ml-2 ml-md-0 mb-sm-0 mt-sm-0">
                                    <div className="mr-2 mr-sm-0">
                                        <img src={recipe2} className="dimension-sm-icon" alt="Recipe-two" />

                                    </div>

                                    <h5 className="text-uppercase  mt-3 icon-title  ml-4 ml-sm-0">
                                        Smart <br></br>Grocery Lists
                                 </h5>
                                    <div className="d-none d-sm-block">
                                        <p className="mt-2 text-center box-paragraph-menu">
                                        Dozens of expertly built recipe plans<br></br> that are 100% customizable.
                                     </p>
                                    </div>
                                </div>
                                <div className="d-flex flex-sm-column text-sm-center mt-5 mt-sm-0 ml-2 ml-md-0">
                                    <div className="mr-2 mr-sm-0">
                                        <img src={recipe3} className="dimension-sm-icon" alt="Recipe-three" />
                                    </div>

                                    <h5 className="text-uppercase icon-title mt-4  ml-4 ml-sm-0">
                                        delivery<br></br>or pickup
                                    </h5>
                                    <div className="d-none d-sm-block">
                                        <p className="mt-2 text-center box-paragraph-menu">
                                        Over 12,000 grocery <br></br>stores to shop from
                                         </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
