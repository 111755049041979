import React, { Component } from 'react'

export default class Socially extends Component {
    render() {
        return (
            <div className="h-100 mt-5">
                <div className="d-flex flex-column">
                <div className="d-flex flex-column align-items-center text-center ">
                    <h2 className="text-uppercase main-title-menud mt-5 text-bold">
                        Socially Acceptable
                    </h2>
                    <p className="italic-text-black mt-5">
                        "I know I need to eat healthier. I just didn’t know where<br></br> to start. "
                    </p>
                    <div className="rectangle-line mt-5"></div>
                    <p className="mt-3 small-text-black mb-5"> Roland M.</p>
                </div>
                </div>
            </div>
        )
    }
}
