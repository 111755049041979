import React, { Component } from 'react'
import kitchens from '../../../assets/grocers/screens/kitchens-1.png';
import kitchens2 from '../../../assets/grocers/screens/kitchens-2.png';

export default class EngageShoppers extends Component {
    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
       
    }

    handleScroll = () => {

        if(window.screen.width>639 && window.screen.width<=737){
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 435 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
         }

        if (window.screen.width > 767 && window.screen.width < 1024) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 487 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
             console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1023 && window.screen.width < 1366) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 555 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1365 && window.screen.width < 1400) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 664 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
    }
    render() {
        return (
            <div className="background-engage">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                    <div className="d-flex flex-column my-sm-auto margin-left-content margins-content-small-text p-sm-4  p-xl-0" ref={this.textScroll}>
                        <h5 className="text-uppercase title-black">
                            Effectively activate
                        </h5>
                        <p className="title-white">
                            Influencers <br />drive economical<br></br> engagement.
                         </p>
                        <p className="text-black-menu-cards mt-4">
                            People want to buy from people. Leverage meal planning to target specific audience with the content they want, when they want it. Market the solution to the busy shopper’s weekly problem.
                         </p>
                    </div>
                    <div className=" d-flex  flex-column flex-sm-row mt-angle-card m-negative-card over-flow-menu-d"onScroll={this.handleScroll.bind(this)} >
                        <div className="d-flex ml-content-cards ml-sm-content-cards">
                            <div ref={this.card}>
                                <div className="card-menud  shadow-sm" >
                                    <img src={kitchens} className="dimensions-images" alt="all-meal" />
                                </div>
                            </div>
                            <div className="ml-3 mr-4">
                                <div className="card-menud shadow-sm">
                                    <img src={kitchens2} className="dimensions-images" alt="meal plans" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-end">
                    <div className="mt-1  mr-4">
                    </div>
                </div>
            </div>
        )
    }
}
