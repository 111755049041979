import React, { Component } from 'react'
import plandetail from '../../../assets/grocers/screens/plan-1-min.png';
import grocery from '../../../assets/grocers/screens/plan-2.png'
export default class ActionableInspiration extends Component {

    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
        this.moveCard = React.createRef();
    }

    handleScroll = () => {
        if (window.screen.width > 767 && window.screen.width < 1024) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 91 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            this.textScroll.current.style = this.initalOpacity
            console.log(this.initalOpacity)
            console.log(x)
        }
        if (window.screen.width > 1023 && window.screen.width < 1200) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 81 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            this.textScroll.current.style = this.initalOpacity
            console.log(this.initalOpacity)
            console.log(x)
        }

        if (window.screen.width > 1500 && window.screen.width < 2000) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 250 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            this.textScroll.current.style = this.initalOpacity
            console.log(this.initalOpacity)
            console.log(x)
        }
        if (window.screen.width > 1365 && window.screen.width < 1500) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 186 ? `opacity: ${opacity}` : `opacity: 1`
            this.moveCard.current.style = "margin-left:30%"
            console.log(this.initalOpacity)
            this.textScroll.current.style = this.initalOpacity
            console.log(x)
        }
    }
    render() {
        return (
            <div className="background-actionable">
                <div className="d-none d-md-block">
                    <div className="d-flex flex-column flex-md-row justify-content-lg-between flex-nowrap mt-5 mr-xl-0">
                        <div className=" d-flex order-1 order-xl-0 position-relative over-flow-menu-d"
                            onScroll={this.handleScroll.bind(this)}>
                            <div className="d-flex">

                                <div className="invisible margin-content-cards">
                                    <div className="card-menud"></div>
                                </div>

                                <div ref={this.moveCard}>
                                    <div className="card-menud shadow-sm">
                                        <img src={plandetail} className="dimensions-images" alt="Plan-detail" />
                                    </div>
                                </div>

                                <div className="ml-4">
                                    <div className="card-menud shadow-sm" ref={this.card}>
                                        <img src={grocery} className="dimensions-images" alt="Grocery" />
                                    </div>
                                </div>

                                <div className="invisible margin-left-invisble-card">
                                    <div className="card-menud shadow-sm">
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex flex-column order-0 order-md-1 m-negative-paragraph mr-auto-menud position-relative"
                            ref={this.textScroll}>
                            <div className="">
                                <h5 className="text-uppercase main-title-menud mt-5 mt-md-0">Purposefully Inspire</h5>
                                <p className="black-paragraph-menud"> Smart link to <br></br> full grocery<br></br> baskets.</p>
                                <p className="text-black-menu-cards">
                                    Transform marketing from brand awareness into shopper activation. Shoppers go from a social media post to a full grocery basket <strong>in just one tap</strong>. 
                                </p>

                            </div>

                        </div>

                    </div>


                </div>
                {/*  View of cards for cell phones and tablets.*/}
                <div className="d-md-none">
                    <div className="d-flex flex-column flex-xl-row mt-5">

                        <div className=" d-flex m-negative-card mr-0 mr-xl-5  order-1 order-xl-0 over-flow-menu-d">
                            <div className="d-flex margin-content-cards-menud">
                                <div className="">
                                    <div className="card-menud shadow-sm">
                                        <img src={plandetail} className="dimensions-images" alt="Plan-detail" />
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <div className="card-menud shadow-sm">
                                        <img src={grocery} className="dimensions-images" alt="Grocery" />
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="d-flex flex-column ml-4 ml-lg-5  order-0 order-xl-1">
                            <h5 className="text-uppercase main-title-menud mt-5 mt-xl-0"> Actionable Inspiration</h5>
                            <p className="black-paragraph-menud"> Smart link to <br></br> full grocery<br></br> baskets.</p>
                            <p className="text-black-menu-cards">
                                Menud drives the shopper from social or paid media<br></br> directly into
                                their recipe plan with full shopping basket.
                          </p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end justify-content-md-start">
                    <div className="mt-4 ml-0 ml-md-4 mr-3 mr-md-0">
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <h5 className="text-uppercase main-title-menud">by the numbers</h5>
                </div>

                <div className="container p-5 mb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-center justify-content-around align-items-center mb-5">
                        <div className="">
                            <div className="box-menud">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="mt-5 p-1">
                                        <h5 className="box-title-menud"> 84%</h5>
                                    </div>
                                    <div className="text-center box-paragraph-menud">
                                        <p>
                                            Millennials who<br></br> don’t trust traditional<br></br> advertising.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="box-menud mt-3 mt-md-0 ml-md-2 ml-xl-0">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="mt-5 p-1">
                                        <h5 className="box-title-menud"> 50%</h5>
                                    </div>
                                    <div className="text-center box-paragraph-menud">
                                        <p>
                                            Millennial shoppers<br></br> who discover new products<br></br> outside the store.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="box-menud mt-3 mt-md-0 ml-md-2 ml-xl-0">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="mt-5 p-1">
                                        <h5 className="box-title-menud"> 90%</h5>
                                    </div>
                                    <div className="text-center box-paragraph-menud">
                                        <p>
                                            Millennials who would switch<br></br> brands to one that is <br></br>associated with a cause.
                                       </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center mt-box-menud">
                    <div className="d-none d-md-block">
                        <h5 className="black-paragraph-menud text-center" >
                            Integrated, Hosted, or White Labeled
                      </h5>
                    </div>

                    {/*title for cell phones and tablets*/}
                    <div className="d-md-none">
                        <h5 className="black-paragraph-menud text-center" >
                            Integrated,<br></br> Hosted, or<br></br> White labeled
                      </h5>
                    </div>
                    <p className="text-black-menu-cards text-center mt-4">
                        Menud is an API technology that deploys to any retailers site or app. Menud created an adaptive web module that can drop into any digital medium in less than a week
                    </p>
                </div>


                <div className="d-none d-xl-block">
                    <div className="mt-5 mb-5">
                        <div className="d-flex justify-content-around justify-content-center">
                            <div className="d-flex  flex-md-column  align-items-center text-center">
                                <h5 className="icon-title text-uppercase mt-3">Integrated Web Experience</h5>
                                <p className="box-paragraph">own the customer journey without he hassel of developing the technology. Customers never leave your site while enjoying recipe planning so that your customer can build larger baskets faster.</p>
                            </div>

                            <div className="d-flex flex-column  align-items-center text-center">
                                <h5 className="icon-title text-uppercase mt-3">White Labelled App</h5>
                                <p className="box-paragraph">
                                    A stand-alone app to launch a new experience. A native app allows for integration of cutting edge iOS technology and App Store optimization.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                {/*square cards for cell phones and tablets */}
                <div className="d-xl-none">
                    <div class="d-flex justify-content-around mt-5">
                        <div className="d-flex flex-column align-items-sm-center m-5 text-center">
                            <h5 className="icon-title text-uppercase mt-3 ">Integrated Web Experience</h5>
                            <p className="box-paragraph">Own the customer journey without he hassel of developing the technology. Customers never leave your site while enjoying recipe planning so that your customer can build larger baskets faster.</p>
                        </div>
                        <div className="d-flex flex-column align-items-sm-center m-5 text-center">
                            <h5 className="icon-title text-uppercase mt-3">White Labelled App</h5>
                            <p className="box-paragraph">
                                A stand-alone app to launch a new experience. A native app allows for integration of cutting edge iOS technology and App Store optimization.
                            </p>
                        </div>

                    </div>
                </div>


            </div>
        )
    }
}
