import React, { Component } from 'react'
import {Switch, Redirect, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Brands from './components/pages/Brands';
import Groceries from './components/pages/Grocers';
import Creator from './components/pages/Creators';
import Tos from './components/pages/Tos';
import Jobs from './components/pages/Jobs';
import Tips from './components/pages/Tips';

export default class Routes extends Component {
    render() {
        return (
            <Switch>
                 
        <Route exact path="/">  
            <Redirect to="/home"/>  
        </Route>  
               
        <Route path="/home" component={Home}/>
        <Route path="/brands" component={Brands}/>
        <Route path="/grocers" component={Groceries}/>
        <Route path="/creator" component={Creator}/>      
        <Route path="/tos-eula" component={Tos}/>
        <Route path="/happyplace" component={Jobs}/>
        <Route path="/tips" component={Tips}/>
            </Switch>
        )
    }
}
