/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import instagram from '../../assets/icon/instagram.png';
import twiter from '../../assets/icon/twitter.png';
import facebook from '../../assets/icon/facebook.png';
import {animateScroll as scroll } from 'react-scroll'
export default class Footer extends Component {
    onClickTop(){
        scroll.scrollTo()
        
    }
    render() {
        return (
            <div className="background-footer d-flex flex-column flex-md-row justify-content-md-around ">

                <div className="d-flex flex-column flex-md-row  align-items-center align-items-md-start my-md-auto ">
                    <div className="ml-0  ml-md-2  ml-lg-3 mt-4 mt-md-0" >
                        <Link to="/creator" className="footer-letter text-uppercase mb-2 mb-md-0 margin-left-creators link-menud" onClick={this.onClickTop.bind(this)}>Creators & Publishers</Link>
                    </div>
                    <div className="ml-0  ml-md-2  ml-lg-3 mt-2 mt-md-0" >
                        <Link to="/grocers" className="footer-letter text-uppercase margin-left-brands link-menud"onClick={this.onClickTop.bind(this)} >Grocers</Link>
                    </div>
                </div>


                <div className="d-flex flex-column flex-md-row  align-items-center align-items-md-start my-md-auto">
                    <div className="ml-0 ml-md-3 ml-lg-3 mt-4 mt-md-0 ">
                        <Link to="/happyplace" className="footer-text-black link-menud" onClick={this.onClickTop.bind(this)} >Join Team Menud</Link>
                    </div>
                    <div className="ml-0  ml-md-3  ml-lg-5 mr-0 mr-md-5 mt-2 mt-md-0">
                        <div className="text-uppercase" >
                            <Link to="/tos-eula" className="footer-text-black link-menud" onClick={this.onClickTop.bind(this)} >Terms of Service</Link>
                        </div>
                    </div>
                    <div className="d-md-none mb-3 mt-3 mt-md-0">
                        <div className="footer-line-divider "></div>
                    </div>
                    <div className="d-flex  flex-md-row  align-items-center align-items-md-start my-md-auto ">

                        <div className=" ml-md-2 ml-lg-3 ">
                            <a href="https://instagram.com/menudmoments" target="_blank">
                                <img src={instagram} alt="instagram" />
                            </a>
                        </div>
                        <div className="ml-4 ml-md-2 ml-lg-3 ">
                            <a href="https://twitter.com/menudmoments" target="_blank">
                                <img src={twiter} alt="twitter" />
                            </a>
                        </div>
                        <div className="ml-4 ml-md-2 ml-lg-3 ">
                            <a href="https://facebook.com/menudmoments" target="_blank">
                                <img src={facebook} alt="facebook" />
                            </a>
                        </div>

                    </div>

                </div>
            </div>

        )
    }
}
