import React, { Component } from 'react'
import meal from '../../../assets/home/screens/Meal-1-min.png';
import allmeal from '../../../assets/home/screens/recipes-all-1-min.png';
export default class Simplify extends Component {
    constructor(props) {
        super(props);
        this.textScroll = React.createRef();
        this.card = React.createRef();
        this.moveCard = React.createRef();

    }
    handleScroll = () => {
        if (window.screen.width > 639 && window.screen.width <= 737) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 435 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)

            this.textScroll.current.style = this.initalOpacity
            this.moveCard.current.style = "margin-right:2rem"
        }
        if (window.screen.width > 767 && window.screen.width <= 1023) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 460 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1023 && window.screen.width <= 1366) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 708 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
        if (window.screen.width > 1365 && window.screen.width <= 1401) {
            let x = this.card.current.getBoundingClientRect().x;
            let opacity = x / window.innerWidth;
            this.initalOpacity = x >= 664 ? `opacity: 1` : `opacity: ${opacity}`
            console.log(this.initalOpacity)
            console.log(x)
            this.textScroll.current.style = this.initalOpacity
        }
    }
    render() {
        return (
            <div className="background-simplify">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between ml-4 mt-sm-content-text">
                    <div className="d-flex flex-column my-auto mt-5 margin-left-content" ref={this.textScroll}>
                        <h5 className="text-uppercase title-black">
                            Make life easier
                        </h5>
                        <div className="d-none d-md-block">
                            <p className="title-white">
                                Cut out the noise<br></br> & focus on what <br></br>matters.
                            </p>

                        </div>
                        <div className="d-md-none">
                            <p className="title-white">
                                Cut out the noise & focus on what matters.
                            </p>

                        </div>
                    </div>

                    <div className=" d-flex  flex-column flex-md-row mt-angle-card m-negative-card over-flow-menu-d" onScroll={this.handleScroll.bind(this)}>
                        <div className="d-flex ml-content-cards">
                            <div ref={this.card}>
                                <div className="card-menud  z-depth-1">
                                    <img src={allmeal} className="dimensions-images" alt="all-meal" />
                                </div>
                            </div>
                            <div className="ml-3 mr-4" ref={this.moveCard}>
                                <div className="card-menud z-depth-1">
                                    <img src={meal} className="dimensions-images" alt="meal plans" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <div className="mt-1  mr-4">
                    </div>
                </div>

                <div className="d-flex flex-column align-items-start align-items-sm-center mt-5 ">
                    <h2 className="white-title-menu ml-3 ml-md-0">
                        3 ways to simplify
                    </h2>
                </div>
                <div className="">
                    <div className="container mt-5">
                        <div className="d-flex flex-column flex-sm-row  justify-content-sm-center  justify-content-sm-around">
                            <div className="text-sm-center ml-3 ml-sm-0">
                                <h5 className="icon-title text-uppercase">
                                    Simplified <br></br>recipes
                              </h5>
                                <div className="d-none d-md-block">
                                    <p className="mt-0 mt-md-3 text-sm-center box-paragraph-menu">
                                        You’re not here to <br></br>read about Karen's grandma’s<br></br>flour sifting technique.
                                     </p>
                                </div>
                                <div className="d-md-none">
                                    <p className="box-sm-paragraph-menu">  You’re not here to read about Karen's grandma’s flour sifting technique.</p>
                                </div>

                            </div>

                            <div className="text-sm-center mt-4 mt-sm-0 ml-3">
                                <h5 className="icon-title  text-uppercase"> shorted<br></br> shopping list
                                 </h5>
                                <div className="d-none d-md-block">
                                    <p className="mt-0 mt-md-3 text-sm-center box-paragraph-menu">
                                        Your ingredients are added<br></br> together saving you time and ink.
                                    </p>
                                </div>
                                <div className="d-md-none">
                                    <p className="box-sm-paragraph-menu">
                                    Your ingredients are added together saving you time and ink.
                                    </p>
                                </div>

                            </div>

                            <div className="text-sm-center mt-4 mt-sm-0 ml-3 ">
                                <h5 className="icon-title  text-uppercase">
                                    reduced<br></br> duplicate buys
                                 </h5>
                                <div className="d-none d-md-block">
                                    <p className="mt-0 mt-sm-3 text-sm-center box-paragraph-menu">
                                    Cook for tonight while<br></br> you plan for tomorrow.
                                     </p>
                                </div>
                                <div className="d-md-none">
                                    <p className="box-sm-paragraph-menu">  
                                    Cook for tonight while you plan for tomorrow.
                                     </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
