import React, { Component } from 'react'

export default class CapturingRecipes extends Component {
    render() {
        return (
            <div className="background-capturing">
                <div className="d-flex justify-content-sm-center">
                    <h5 className="main-title-menud text-uppercase mt-5 ml-4 ml-sm-0">Capturing Recipes</h5>
                </div>
                <div className="container">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-center mt-5 justify-content-sm-around ml-3 ml-sm-0 =">
                        <div className="d-flex flex-column align-items-sm-center creators--importBlock">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">Self-Managed</h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu">
                                    Input recipes using Menūd’s<br></br> intelligent and user-friendly<br></br> recipe management system.
                                </p>
                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu">
                                    Input recipes using Menūd’s intelligent and user-friendly recipe management system.
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-column mt-5 mt-sm-0 creators--importBlock">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center">Content Import</h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu">
                                    Menud imports, codifies, and prepares your recipes using an API or a worksheet.  
                                </p>
                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu">
                                    Menūd can import recipes from your database.
                                </p>
                            </div>

                        </div>
                        <div className="d-flex flex-column mt-5 mt-sm-0 creators--importBlock">
                            <h5 className="font-weight-bold-menud text-uppercase text-sm-center"> Scraping</h5>
                            <div className="d-none d-md-block">
                                <p className="box-paragraph-menu ">
                                    Have recipes scraped,<br></br> codified, and structured using<br></br>natural language processing<br></br> and machine learning.
                             </p>
                            </div>
                            <div className="d-md-none">
                                <p className="box-sm-paragraph-menu">
                                    Have recipes scraped, codified, and structured using natural language processing and machine learning.
                                </p>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        )
    }
}
