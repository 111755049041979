import React, { Component } from 'react'

export default class InformationSection extends Component {
    render() {
        return (

            <div className="background-information-section d-flex flex-column flex-xl-row justify-content-start justify-content-lg-around">
                

                <div className="mt-content-information mt-content-md-information mt-content-sm-information  p-4 mb-5 mb-lg-0">
                    <h5 className="text-white-information">55%</h5>
                    <p className="paragraph-bold-menud">
                        Millennials who say convenience is a top driver when buying food.
                        </p>
                    <p className="mt-1">
                        — International Food Information Council
                      </p>
                </div>


                <div className="mt-content-information  p-4"> 
                    <h5 className="text-white-information">76%</h5>
                        <p className="paragraph-bold-menud">
                            Online grocery shoppers who would switch stores for a better digital experience.
                        </p>
                        <p className="mt-1">
                            — International Food Information Council
                        </p>
                </div>

            </div>



        )
    }
}
