import React from 'react';

import Routes from './Routes';



function App() {
  return (

  
   

    <div >
      <Routes/>

    </div>
  );
}

export default App;
