import React, { Component } from 'react'
import MainSection from '../sections/home/MainSection';
import Savetime from '../sections/home/saveTime';
import MealPlans from '../sections/home/MealPlans';
import Simplify from '../../components/sections/home/Simplify';
import  Socially from '../../components/sections/home/Socially';
import  DownloadMenud from '../sections/home/DownloadMenud';
import Footer from '../UI/Footer';
export default class Home extends Component {
    render() {
        return (
            <div>
                 <MainSection/> 
                 <Savetime/> 
                  <MealPlans/>  
                  <Simplify/>   
                 <Socially/> 
                <DownloadMenud/>
                <Footer/>
            </div>
        )
    }
}
